<template>
  <Box>
    
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    
    <Row v-if="!isLoading">
      <Column>
        <ComplianceTable :start="startComponent" :isAdmin="false" />
      </Column>
    </Row>
    
    <LoadingRow v-if="isLoading">{{ loadingMessage }} </LoadingRow>
      
  </Box> <!-- container -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

//import ConstUtils      from '@/utils/ConstUtils.js';
import ConnectionUtils      from '@/utils/ConnectionUtils.js';
import ComplianceTable from './ComplianceTable.vue';
import Catelog         from "@/domain/session/CanadianEnglish.js";
import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
import LoadingRow from '@/portals/shared/library/loading/LoadingRow.vue';
import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: 'portal-compliance',
  components: {
    ComplianceTable,
    LoadingRow,
    Box, Row, Column,
    SessionTimeoutDialog,
  },
  props: {
  },
  data() {
    return {
      startComponent: false,
      K: Catelog.KEYS,
      C: new Catelog(),
      message: null,
      isLoading: true,
      loadingMessage: "",
      
      showSessionTimeoutDialog: false,
    }
  },
  computed: {
    ...mapGetters([
                    'auth_connected',
                    'auth_socket_status',
                    'signin_event',
                    'auth_client',
                    'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                 ]),

    start: function() {
      this.isLoading = false;
      this.startComponent = true;
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  },
}
</script>